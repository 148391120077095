<template>
    <div>
        <div class="title">
            <img src="	https://www.up-art.cn/skin/images/sq/jiamengyoushi2.jpg" alt="">
            <div class="container px-0 ">
                <el-carousel :interval="5000" :height="swiperHeight" arrow="always">
                    <el-carousel-item v-for="item in cultural_creation_support.data" :key="item">
                        <div class="d-flex justify-content-center  h-100 w-100">
                            <div class="container px-0-5 w-100 h-100  d-flex align-items-center justify-content-center">
                                <img class="w-100" :src="$imgURL+item" alt="">
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <!-- 九大课程列表 -->
        <div class="d-flex justify-content-center content">
            <div class="rec-wrap container  py-2">
                <div class="home-title mb-1 mb-sm-2">
                    <div class="font-md text-center">{{ configs.overview_name }}</div>
                    <div class="font text-center font-line">{{ configs.overview_eng }}</div>
                    <div class="font text-center">{{ configs.overview_desc }}</div>


                </div>


                <div class="d-flex flex-column p-0-5 p-md-0">
                    <div class="d-flex flex-row flex-wrap">
                        <div class="kecheng-p col-12 col-md-6" v-for="(item,index) in overviews" v-if="index<=5"
                            :key="index">
                            <div class=" p-0  rounded-ssm  d-flex flex-row">
                                <div class="col-5   p-0  " v-if="index%2==0">

                                    <el-carousel :loop="true" :height="isSmall?'160px':'226px'" arrow="nerver"
                                        indicator-position="none">
                                        <el-carousel-item v-for="item1 in item.images" :key="item1">
                                            <div class="d-flex justify-content-center h-100">
                                                <img :src="$imgURL+item1" class="w-100 h-100 obj-cover" alt="">
                                            </div>
                                        </el-carousel-item>
                                    </el-carousel>
                                </div>
                                <div class="col-7  bg-kecheng d-flex  px-1 flex-column justify-content-center "
                                    :class="[index==1||index==2||index==5||(index==4 && isSmall)?'text-white':'',index==1||(index==5 && !isSmall)||(index==4 && isSmall)?'bg-yellow1':'',index==2||(index==5&&isSmall)?'bg-blue':'',index%2==0?'':' text-right']">
                                    <div class="font-smd  font-weight-bold">{{item.title}}</div>
                                    <div class="font mt-0-5">{{item.desc}}</div>
                                </div>



                                <div class="col-5  p-0 " v-if="index%2!=0">

                                    <el-carousel :height="isSmall?'160px':'226px'" arrow="nerver"
                                        indicator-position="none">
                                        <el-carousel-item v-for="item1 in item.images" :key="item1">
                                            <div class="d-flex justify-content-center h-100">
                                                <img :src="$imgURL+item1" class="w-100 h-100 obj-cover" alt="">
                                            </div>
                                        </el-carousel-item>
                                    </el-carousel>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="d-flex flex-column flex-md-row  " style="margin-top: 0.08rem;">
                        <div class="col-12 col-md-7  p-0 d-flex flex-column">
                            <div class="kecheng-p" v-for="(item,index) in overviews" :key="index"
                                v-if="index>=6&&index<8">
                                <div class="d-flex border-kecheng  flex-row rounded-ssm">
                                    <div class="col-6 px-1  " v-if="index==7&&isSmall">
                                        <div class="   h-100 d-flex flex-column justify-content-center ">
                                            <div class="font-smd font-weight-bold">{{item.title}}</div>
                                            <div class="font mt-0-5">{{item.desc}}</div>
                                        </div>
                                    </div>
                                    <div class="col-6 p-0">

                                        <el-carousel direction="vertical" :height="isSmall?'160px':'286px'"
                                            arrow="nerver" indicator-position="none">
                                            <el-carousel-item v-for="item1 in item.images" :key="item1">
                                                <div class="d-flex justify-content-center h-100">
                                                    <img :src="$imgURL+item1" class="w-100 h-100 obj-cover" alt="">
                                                </div>
                                            </el-carousel-item>
                                        </el-carousel>
                                    </div>
                                    <div class="col-6 px-1 " :class="index==6 && isSmall?'d-block':'d-block'">
                                        <div class="   h-100 d-flex flex-column justify-content-center ">
                                            <div class="font-smd font-weight-bold">{{item.title}}</div>
                                            <div class="font mt-0-5">{{item.desc}}</div>
                                        </div>
                                    </div>

                                </div>

                            </div>


                        </div>
                        <div class="col-12 col-md-5   p-0  ">
                            <div class="kecheng-p ">
                                <div class=" d-flex flex-row border-kecheng rounded-ssm">
                                    <div class="col-6 col-sm-7 p-0 ">
                                        <el-carousel :loop='true' direction="vertical" :height="isSmall?'160px':'590px'"
                                            arrow="nerver" indicator-position="none">
                                            <el-carousel-item class="h-100" v-for="item1 in overviews[8].images"
                                                :key="item1">
                                                <div class="d-flex justify-content-center h-100">
                                                    <img :src="$imgURL+item1" class="w-100 h-100 obj-cover" alt="">
                                                </div>
                                            </el-carousel-item>
                                        </el-carousel>
                                    </div>
                                    <div
                                        class="col-6 col-sm-5 px-1 py-0 d-flex align-items-sm-center justify-content-sm-end justify-content-center pb-0  pb-sm-2 flex-column ">
                                        <div class=" w-100px   d-none d-sm-block">
                                            <div class="font-smd font-weight-bold">{{overviews[8].title.split(',')[0]}}
                                            </div>
                                            <div class="font-smd  font-weight-bold">{{overviews[8].title.split(',')[1]}}
                                            </div>
                                        </div>
                                        <div class="    d-block d-sm-none">
                                            <div class="font-smd font-weight-bold">
                                                {{overviews[8].title.split(',')[0]}}{{overviews[8].title.split(',')[1]}}
                                            </div>

                                        </div>

                                        <div class="font  mt-0-5 w-100px">{{overviews[8].desc}}</div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <!-- <div class="content px-0-5 px-sm-0">
					<div class="item" v-for="(item, index) in overviews" :key="index">
						<div class="top-wrap ">
							<div class="top font-smd px-1 px-sm-2 py-0-5">{{ item.title }}</div>
						</div>
						<div class="round mt-0-5 mt-lg-0 bg-light px-1 px-sm-2  py-0-5 py-sm-1  font d-flex align-items-center flex-row">
							<div v-if="index%2!=0" class="b0t-t2 font-md col-1  d-flex justify-content-center ">0{{ index + 1 }}</div>
							<div class="col-11 ">{{ item.desc }}</div>
							<div v-if="index%2==0" class="b0t-t2 font-md col-1  d-flex justify-content-center ">0{{ index + 1 }}</div>
						</div>
					</div>
				</div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            swiperHeight: '100%',
            cultural_creation_support: [],
            configs: "", //module 标题文案
            overviews: [], //九大课程
            isSmall: '',
        }
    },
    created() {
        this.curriculums();
    },
    mounted() {
        this.onConfig()
        let that = this
        that.isSmall = document.body.clientWidth < 567 ? true : false
        that.isMiddle = document.body.clientWidth <= 1024 && document.body.clientWidth > 567 ? true : false
        // let type = this.$route.query.type
        // this.scrollView(type)
        this.windowWidth = window.innerWidth
        window.onresize = () => {
            if (!this.timer) { // 使用节流机制，降低函数被触发的频率
                this.timer = true;
                let that = this; // 匿名函数的执行环境具有全局性，为防止this丢失这里用that变量保存一下
                setTimeout(function () {
                    that.searchFormWidth();
                    that.timer = false;
                    that.isSmall = document.body.clientWidth < 567 ? true : false
                    that.isMiddle = document.body.clientWidth <= 1024 && document.body.clientWidth > 567 ? true : false
                }, 400)
            }
        }
    },
    methods: {
        onConfig() {
            // console.log(key);
            var that = this;
            that.api.config('cultural_creation_support').then((res) => {
                if (res.data.code == 1) {
                    this['cultural_creation_support'] = res.data;
                }
            });
        },
        curriculums() {
            this.$axios({
                method: "post",
                url: this.$apiURL + "api/Curriculums/index",
            }).then((res) => {
                if (res.code < 1) {
                    console.log("网络错误");
                    return false;
                }
                this.configs = res.data.data.configs;
                this.classs = res.data.data.classs;
                this.overviews = res.data.data.overviews;
                this.systems = res.data.data.systems;
            });
        },
        curriculums() {
            this.$axios({
                method: "post",
                url: this.$apiURL + "api/Curriculums/index",
            }).then((res) => {
                if (res.code < 1) {
                    console.log("网络错误");
                    return false;
                }
                this.configs = res.data.data.configs;
                this.classs = res.data.data.classs;
                this.overviews = res.data.data.overviews;
                this.systems = res.data.data.systems;
            });
        },
    },
}
</script>
<style scoped>
.w-100px {
    width: 100px;
}

.border-kecheng {

    border: 5px solid #EEEEEC;
}

.bg-yellow1 {
    background-color: #FFC421 !important;
}

.bg-kecheng {
    background-color: #EEEEEE;
}

.kecheng-p {
    padding: 0.04rem;
}

.rounded-ssm {
    border-radius: 10px;
    overflow: hidden;
}

.obj-cover {
    object-fit: cover;
}

.w-600 {
    width: 550px;
}

.b0t-t2 {
    font-size: 0.36rem;
    font-weight: 500;
    color: #c8c8c8;
}

.round {
    border-radius: 2rem;
}

.h-8 {
    height: 8rem;
}

@media (max-width:960px) {


    .w-600 {
        width: auto !important;
    }

    .h-8 {
        height: auto;
    }
}

@media (max-width:576px) {
    .w-100px {
        width: auto !important;
    }

    .w-600 {
        width: auto !important;
    }

    .el-carousel__container {
        height: 400px !important;
    }

    .h-8 {
        height: auto;
    }
}
</style>
<style lang="scss">
.title {
    width: 100%;
    height: 6.6rem;
    background-color: #fec900;
    display: flex;
    justify-content: center;

    img {
        display: block;
        height: 100%;

    }

    .container {
        width: 60%;

        .el-carousel {
            height: 100% !important;


            button {
                display: none;
            }

            img {
                object-fit: cover;
            }
        }

    }
}

.content {
    width: 80%;
    margin: 0 auto;

    .font-smd {
        font-size: .2rem;
    }

    .font {
        font-size: .16rem;
    }

    .font-md {
        font-size: .3rem;
    }
}

.rec-wrap {
    // width: 1500px;
    // padding: 0.63rem 0;
    box-sizing: border-box;

    .home-title {
        // margin-bottom: 0.73rem;
    }

    .content {
        .item {
            // width: 100%;

            &:nth-child(odd) .top-wrap {}

            // 偶数
            &:nth-child(even) .top-wrap {
                flex-direction: row-reverse;
            }

            // 奇数

            &:nth-child(odd) .top-wrap .top {
                background: #9fd9f6;
                transform: translate(0.62rem, 50%);

                color: #fff;
            }

            // 偶数
            &:nth-child(even) .top-wrap .top {
                background: #fdd23e;
                transform: translate(-0.62rem, 50%);

                color: #333;
            }

            &:nth-child(odd) .bottom {}

            // 偶数
            &:nth-child(even) .bottom {
                flex-direction: row-reverse;
            }

            .top-wrap {
                width: 100%;
                // 奇数
                display: flex;
                font-size: 0.2rem;

                .top {
                    // padding: 0 0.37rem;
                    // height: 0.7rem;
                    border-radius: 0.78rem;
                    // line-height: 0.7rem;
                    letter-spacing: 0.04rem;

                    font-size: 0.22rem;
                    font-weight: 600;
                }
            }

            .bottom {
                letter-spacing: 0.03rem;
                font-size: 0.18rem;
                width: 100%;
                // height: 1.2rem;
                background: #f6f0f2;
                border-radius: 0.78rem;

                // 奇数
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 0.20rem;
                padding: 0 0.56rem;
                box-sizing: border-box;

                .b0t-t1 {
                    width: 10rem;
                }

                .b0t-t2 {
                    font-size: 0.36rem;
                    font-weight: 500;
                    color: #c8c8c8;
                }
            }
        }
    }
}
</style>